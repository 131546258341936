.rbc-header {
    background: #e53935;
    color: white;
    font-size: 1rem;
    text-transform: capitalize;
    padding: 4px;
    height: 35px;
}

.rbc-time-header {
    height: 100%;
}

.rbc-time-content {
    display: none !important;
}

.rbc-time-header-gutter {
    display: none;
}

.rbc-event {
    display: flex;
    align-items: center;
}

.event-hover-effect {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.event-hover-effect:hover {
    transform: scale(1.003);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}

.rbc-event-content {
    width: 100% !important;
}

.rbc-toolbar-label {
    color: #3d3d3d;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
}

.rbc-time-view::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background-image: url("../../src/assets/LogoCalendar.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.4;
    z-index: 0;
    pointer-events: none;
}

.rbc-calendar .rbc-time-view,
.rbc-calendar .rbc-month-view,
.rbc-calendar .rbc-agenda-view {
    position: relative;
    z-index: 1;
}

.rbc-today {
    background: #ff8a80 !important;
    opacity: 0.7 !important;
}

/* Header Buttons */
.rbc-btn-group {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.rbc-btn-group button {
    width: 33.3333% !important;
    color: #36454f !important;
    transition: transform 0.15s ease, box-shadow 0.15s ease !important;
}

.rbc-btn-group button:hover {
    transform: scale(1.03) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15) !important;
}

.rbc-btn-group button:focus,
.rbc-btn-group button:active,
.rbc-btn-group button.rbc-active {
    background: #ff8a80 !important;
}

.rbc-allday-cell {
    overflow: auto !important;
    width: 100% !important;
}

/* Project "jour" */

.rbc-event.event-jour,
.rbc-agenda-table.rbc-agenda-event-cell.event-jour,
.rbc-agenda-view .rbc-agenda-event-cell.event-jour {
    background: #add8e6 !important;
    color: #3d3d3d !important;
    outline: 1px solid #3d3d3d !important;
    display: flex;
    height: 100dvh !important;
    min-height: 100px !important;
    align-items: center;
}

/* Project "nuit" */

.rbc-event.event-nuit,
.rbc-agenda-table.rbc-agenda-event-cell.event-nuit,
.rbc-agenda-view .rbc-agenda-event-cell.event-nuit {
    background: #002244 !important;
    color: #faf9f6 !important;
    outline: 1px solid #faf9f6 !important;
    display: flex;
    height: 100dvh !important;
    min-height: 100px !important;
    align-items: center;
}

/* Week  */

.rbc-time-header-cell {
    flex-direction: column !important;
    height: 100% !important;
    width: fit-content;
}

.rbc-calendar.week-view .rbc-allday-cell .rbc-row-content {
    display: flex !important;
    flex-direction: row !important;
    overflow: auto !important;
    width: 100% !important;
    height: 100% !important;
}

.rbc-time-header-cell .rbc-header {
    font-size: 0.7rem !important;
}

.rbc-calendar.week-view .rbc-time-header .rbc-time-header-content {
    flex-direction: row;
}

.rbc-calendar.week-view .rbc-allday-cell .rbc-event {
    width: 120px;
    height: 100% !important;
}

.rbc-calendar.week-view
    .rbc-time-header-content
    .rbc-allday-cell
    .rbc-row-content
    .rbc-row {
    flex-direction: column !important;
    height: 100% !important;
    width: 100%;
}

.rbc-calendar.week-view .rbc-row-bg {
    flex-direction: column !important;
}

/* Agenda */

.rbc-agenda-time-cell {
    display: none;
}

.rbc-agenda-date-cell {
    width: 70px !important;
    padding: 0.25rem !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    white-space: wrap !important;
    border-right: 2px solid #ddd !important;
}

.rbc-agenda-event-cell {
    width: 100% !important;
    display: flex;
    align-items: flex-start;
    overflow: visible;
    border-left: none !important;
}

.rbc-agenda-table .rbc-agenda-event-cell {
    table-layout: fixed !important;
    width: 100% !important;
}

.rbc-agenda-table .rbc-header:nth-child(2) {
    display: none;
}

.rbc-agenda-table .rbc-header:nth-child(1) {
    width: 70px !important;
}

.rbc-agenda-time-cell {
    display: none;
}

/* Day */

.rbc-calendar.day-view .rbc-allday-cell .rbc-event {
    height: 80px !important;
    margin: 0.25rem 0 0.25rem 0;
    border-radius: 15px;
    height: 100% !important;
}

@media (min-width: 768px) {
    .rbc-btn-group button {
        width: 25% !important;
    }
}

@media (min-width: 990px) {
    .rbc-time-header-cell {
        flex-direction: row !important;
        width: 100% !important;
        height: auto !important;
    }

    .rbc-calendar.week-view .rbc-allday-cell .rbc-row-content {
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        height: 100% !important;
    }

    .rbc-calendar.week-view .rbc-time-header .rbc-time-header-content {
        flex-direction: column;
    }

    .rbc-calendar.week-view
        .rbc-time-header-content
        .rbc-allday-cell
        .rbc-row-content
        .rbc-row {
        flex-direction: row !important;
        height: auto !important;
        width: 100% !important;
    }

    .rbc-calendar.week-view .rbc-row-bg {
        flex-direction: row !important;
    }

    .rbc-event {
        width: 100% !important;
    }

    .rbc-time-header-cell .rbc-header {
        font-size: 1rem !important;
    }

    .rbc-agenda-date-cell {
        width: 100px !important;
        text-align: center;
        font-size: 14px !important;
        font-weight: bold !important;
    }

    .rbc-agenda-table .rbc-header:nth-child(1) {
        width: 100px !important;
    }

    .rbc-btn-group button {
        width: 10% !important;
    }
}
